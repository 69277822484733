import { Injectable } from '@angular/core';
import { MainService } from './_base_services/main.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandleService } from './_base_services/error-Handle.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends MainService {

  private loggedIn = false;
  private user:any ;
  isAdmin = false;

  constructor(private httpx: HttpClient, errorHandleService: ErrorHandleService) {
    super(httpx, errorHandleService);
     this.loggedIn = !!localStorage.getItem('token');
   /*   this.user = this.getUserDetails(); */
  }

  isLoggedIn() {
    return this.loggedIn;
  }


  isUserAdmin(){
    return this.isAdmin;
  }
  
  getUser(){
    return this.user;
  }

  getUserId(){
    let id = this.user['_id'];
    return id;
  }




/*   getUserDetails(){    // : Observable<any>    şimdilik sildik
    if (this.isLoggedIn()) {
       const myRawToken = localStorage.getItem('token')
       const jwtHelper = new JwtHelperService();
       const decodedToken = jwtHelper.decodeToken(myRawToken).user;
     //  const isRolesArray = Array.isArray(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
     const isRolesArray = Array.isArray(decodedToken['roles']);

       console.log('role array  -- ' , isRolesArray)
      // const testR = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].slice(0);
       const testR = decodedToken['roles'].slice(0);
        if (!isRolesArray && testR == 'Admin') {
          this.isAdmin = true;
        } 
        if (isRolesArray) {
          this.isAdmin = testR.some(x=> x == 'Admin');
        } 
       console.log('-TESTR -' , testR , this.isAdmin , decodedToken)

      // this.isAdmin = isRolesArray.some(x=> x == 'admin' )
       return decodedToken;
    }
  } */
}
