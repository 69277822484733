import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateNavComponent } from './corporate-nav.component';
import { RouterModule } from '@angular/router';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon'


@NgModule({
  declarations: [CorporateNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    NzDrawerModule,
    NzIconModule 
  ],
  exports: [CorporateNavComponent]
})
export class CorporateNav01Module { }
