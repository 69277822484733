import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';




@NgModule({
  declarations: [
    FooterComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    RouterModule,
 
    

  ],
  exports: [FooterComponent]
 
})
export class FooterModule { }
