<div class="main-wrapper">


<header>
    <div class="container">
        <app-corporate-nav></app-corporate-nav>
    </div>
</header>

<div class="container">
    <router-outlet></router-outlet>
</div>

<footer class="container">
    <app-footer></app-footer>
</footer>

</div>
