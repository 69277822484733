import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import "rxjs/add/operator/do";
// import Swal from 'sweetalert2';
export class AuthInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        let authToken = localStorage.getItem('token');
     //   alert('auth-token : auth intercept ' + authToken)
        if (authToken == null) {
            authToken =  sessionStorage.getItem('token');
        }
        let newRequest: HttpRequest<any>;
       
        if(request.url != "https://fcm.googleapis.com/fcm/send"){
            newRequest = request.clone({
                // headers: request.headers.set("Authorization", `Bearer ${token}`)
                headers: request.headers.set("Authorization" ,  `Bearer ${authToken}`),
             });
            
        }
        else{
            newRequest = request.clone();
        }
        
      //   alert('INTER')
   
 
        return next.handle(newRequest)
            .do(
                success => {
                    if (success.type !== 0) {
                    }
                },
                error => {
                    console.error('interceptordan gelen hata' , error);
                    console.log(request.url);
                    if (request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE' ) {
                 //       alert('HATAAAA INTERCEPTOR');
                  /*       Swal.fire(
                            'Hata Oluştu!',
                            `${error.error}`,
                            'error'
                          ); */
                    }
                  
                }
            );
    }
}
