import { Component, OnInit } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-corporate-nav',
  templateUrl: './corporate-nav.component.html',
  styleUrls: ['./corporate-nav.component.scss']
})
export class CorporateNavComponent implements OnInit {
  visible = false;


  constructor() { }
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
  ngOnInit(): void {}

}
