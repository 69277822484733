import { Injectable } from '@angular/core';
import { MainService } from './_base_services/main.service';
import { ErrorHandleService } from './_base_services/error-Handle.service';
import { HttpClient } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';



declare var $:any;

@Injectable()
export class GlobalService extends MainService {

  constructor(http: HttpClient, errorHandleService: ErrorHandleService, private notification: NzNotificationService) {
    super(http, errorHandleService);
  }

  clearStorage() {
    sessionStorage.removeItem('supplierAuth');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('role')
    localStorage.removeItem('role')
    localStorage.removeItem('supplierAuth');
    localStorage.removeItem('expiration');
    sessionStorage.removeItem('token')
    localStorage.removeItem('token')
    
  }

  formValidationMessage(invalidItems) {
    let fControls = invalidItems;
    for (const key in fControls) {
      if (Object.prototype.hasOwnProperty.call(fControls, key)) {
        const element = fControls[key];
        if (element.status == 'INVALID') {
          this.notification.create('error', `${key} boş bırakılamaz`, null, { nzKey: `${key}` });
        }
      }
    }
  }

  createNotification(type, title, content) {
    this.notification.create(type, title, content);
  }

  getCountries(){
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/countries').subscribe((resp:any)=>{
        console.log('resp' , resp)
        resolve(resp.data)
      })
    })
  }







  

      

    }



