import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './_default_layout';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/anasayfa' }, 
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      { path: 'hakkimizda', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
      { path: 'iletisim', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
      { path: 'ebeveyn-odasi', loadChildren: () => import('./parents-room/parents-room.module').then(m => m.ParentsRoomModule) },
    ]
  },
 // { path: '**', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
