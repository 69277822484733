<div class="navbar-container">
    <div class="navbar-content">
        <div class="nav-left">
            <img routerLink="/anasayfa" src="./../../../assets/logo.png" alt="">
            <!-- <h3 routerLink="/anasayfa">Tommee Tippee TR</h3> -->
        </div>
        <div class="nav-right">
            <ul>
                <li routerLink="/anasayfa"><a>Ana Sayfa</a></li>
                <li routerLink="/ebeveyn-odasi"><a>Ebeveyn odası</a></li>
                <li routerLink="/hakkimizda"><a>Hakkımızda</a></li>
                <li routerLink="/iletisim"><a>Bize Ulaşın</a></li>

            </ul>
            <button nz-button nzType="primary" (click)="open()"><i class="fa fa-bars" aria-hidden="true"></i></button>
        </div>
    </div>
</div>

<div class="nav-right-drawer">
    <nz-drawer [nzClosable]="false" [nzVisible]="visible" nzPlacement="left" nzTitle="Tommee Tippee" (nzOnClose)="close()" [nzZIndex]="10000">
        <ng-container *nzDrawerContent>
            <ul>
                <li routerLink="/anasayfa" (click)="visible=false"><a>Ana Sayfa</a></li>
                <li routerLink="/ebeveyn-odasi" (click)="visible=false"><a>Ebeveyn odası</a></li>
                <li routerLink="/hakkimizda" (click)="visible=false"><a>Hakkımızda</a></li>
                <li routerLink="/iletisim" (click)="visible=false"><a>Bize Ulaşın</a></li>
            </ul>
        </ng-container>
    </nz-drawer>
</div>