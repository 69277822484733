import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { ErrorHandleService } from './_base_services/error-Handle.service';
import { MainService } from './_base_services/main.service';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService extends  MainService {


  constructor(private httpx: HttpClient, errorHandleService: ErrorHandleService ) {
    super(httpx, errorHandleService);
  }

 /*   getAllUsers():any {
    this.Get('/Users/getall').subscribe((resp:any)=>{
      console.log('GetAllUsers' , resp);
      return resp.data;
    })
  }  */

  getAllWorkOrders():any {
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/workorders/getall').subscribe((resp:any)=>{
        console.log('workorders' , resp);
        resolve(resp.data);
      })
    })
  }

  getAllUsers():any {
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/Users/getall').subscribe((resp:any)=>{
        console.log('GetAllUsers' , resp);
        resolve(resp.data);
      })
    })
  }

  
  /* getAllCustomers():any {
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/Customers/getall').subscribe((resp:any)=>{
        console.log('GetAllCustomers' , resp);
        resolve(resp.data);
      })
    })
  } */


  getAllStatuses():any {
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/Status/getall').subscribe((resp:any)=>{
        console.log('GetAllStatus' , resp);
        resolve(resp.data);
      })
    })
  }
 

  getFaultTypes():any{
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/FaultTypes/getall').subscribe((resp:any)=>{
        console.log('GetAllFaultTypes' , resp);
        resolve(resp.data);
      })
    })
  }

  getFaultRequests():any{
    return new Promise<Array<any>>((resolve)=>{
      this.Get('/FaultRequests/getall').subscribe((resp:any)=>{
        console.log('GetAllRequests' , resp);
        resolve(resp.data);
      })
    })
  }

  getWorkOrderStatusHistoy(id):any{
    return new Promise<Array<any>>((resolve)=>{
      this.GetID('/WorkOrderStatus/getallbyworkorderid', id).subscribe((resp:any)=>{
        console.log('getWorkOrderStatusHistoy' , resp);
        resolve(resp.data);
      })
    })
  }

  ///api/WorkOrderStatus/getallbyworkorderid/{id}

  }


  


