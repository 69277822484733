<div class="footer-container">
    <div class="footer-content">
        <p>Tüm Hakları Saklıdır @ 2022</p>
        <div class="footer-contact">
            <h5>Bizi takip etmeyi unutmayın</h5>
            <ul>
                <li><a href="https://www.youtube.com/channel/UCyab-b-8Nz6EVk5oMIlxXaQ" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                <li><a href="https://www.facebook.com/tommeetippeetr" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/tommeetippeetr/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            </ul>
        </div>
        <p>Burda Bebek Ürünleri San. ve Tic. A.Ş.</p>
    </div>
</div>