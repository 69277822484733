import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CorporateNav01Module } from './@modules/corporate-nav-01/corporate-nav-01.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './_default_layout';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FooterModule } from './@modules/footer/footer.module';
import { GlobalService } from './@core/modules/services/global.service';
import { UserService } from './@core/modules/services/user.service';
import { WorkOrderService } from './@core/modules/services/work-order.service';
import { AppGlobalErrorHandler } from './@core/modules/services/_base_services/app-global-errorHandler';
import { AuthGuard } from './@core/modules/services/_base_services/auth.guard';
import { OnlyAdmins } from './@core/modules/services/_base_services/auth.only-admins';
import { OnlyCashier } from './@core/modules/services/_base_services/auth.only-cashier';
import { AuthInterceptor } from './@core/modules/services/_base_services/AuthInterceptor';
import { ErrorHandleService } from './@core/modules/services/_base_services/error-Handle.service';
import { MainService } from './@core/modules/services/_base_services/main.service';
import { RoleGuardService } from './@core/modules/services/_base_services/role-guard.service';
import { StateMainService } from './@core/modules/services/_base_services/state-main.service';
import { UserRolesService } from './@core/modules/services/_base_services/user-roles.service';



registerLocaleData(en);

const _default_layout = [
  DefaultLayoutComponent
];


@NgModule({
  declarations: [
    AppComponent,
    ..._default_layout
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CorporateNav01Module,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzIconModule,
    FooterModule,
  ],
  providers: [
    MainService,
    StateMainService,
    GlobalService,
    UserService,
    ErrorHandleService,
    WorkOrderService,
    AuthGuard,
    OnlyAdmins,
    OnlyCashier,
    UserRolesService,
    RoleGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppGlobalErrorHandler },
    { provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})
export class AppModule { }
